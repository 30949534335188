@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: $spacing-md;

	@include xs-only {
		grid-template-columns: repeat(2, 1fr);
	}
}

.max_width_wrapper {
	max-width: 90rem;
	margin-inline: auto;

	position: relative;
}

.container {
	background: white;
	padding: $spacing-xl $spacing-lg 2rem;
	margin: 0 auto;

	.heading {
		text-align: left;
		text-wrap: balance;
		color: $black;
		max-width: 30ch;
		margin-bottom: $spacing-lg;

		> span {
			opacity: 0.3;
		}

		@include sm-down {
			text-align: center;
			margin-bottom: $spacing-md;
			max-width: 20ch;

			margin-inline: auto;
		}
	}

	@include sm-down {
		padding: $spacing-lg $spacing-sm;
	}

	.wrapper {
		position: relative;

		max-width: 90rem;
		margin-inline: auto;

		&::after {
			content: '';
			display: block;
			z-index: 2;
			position: absolute;
			// This makes the gradient cover an area that's slightly larger than the container, to
			// account for bottom-right card rendering slightly outside of the container when it's
			// skewed/rotated.
			top: 0;
			left: 0;
			right: 0;
			bottom: -32px;
			pointer-events: none;
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0%) 40%, rgba(255, 255, 255, 95%) 90%);

			@include xs-only {
				background: linear-gradient(to bottom, rgba(255, 255, 255, 0%) 60%, rgba(255, 255, 255, 95%) 95%);
			}
		}
	}
}

.cta_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;
	top: 0;
	right: 0;

	@include sm-down {
		margin-top: $spacing-lg;
		position: static;
	}

	svg {
		margin-inline-start: 0.5rem;

		width: 1.5rem;
		height: 1.5rem;
	}
}

.masonry {
	display: flex;
	gap: 1rem;

	margin: 0;

	> ul {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		list-style: none;
		margin: 0;
	}
}

.masonry_item {
	padding: 0;

	max-height: 100%;

	> div,
	a {
		display: block;
		width: 100%;
		max-height: 100%;
	}

	> a {
		background-color: #ececec;
		border-radius: 6px;
	}

	.quote_card {
		display: flex;
	}

	@include xs-only {
		p,
		a,
		span,
		strong,
		h2,
		h3,
		div {
			font-size: 10px;
			line-height: 1.2;
		}

		:global(.article_content),
		:global(.wall-of-love-article) {
			min-height: 0;
			aspect-ratio: 390 / 690;
			padding: $spacing-sm;

			p,
			a,
			span,
			strong,
			h2,
			h3,
			div {
				font-size: 10px;
				line-height: 1.2;
			}
		}

		:global(.wall-of-love-tweet) {
			padding: $spacing-xs;
		}
	}
}
